/* -------------------------------------------------------------------------------- *\
|                               STATETYPES
\* -------------------------------------------------------------------------------- */
export enum STATETYPES
{
    STATETYPE_TEXT = "text",
    STATETYPE_BOOL = "bool",
    STATETYPE_GAUGE = "gauge",
    STATETYPE_LIVEINFO = "live",
    STATETYPE_HTTP = "http",
}
/* -------------------------------------------------------------------------------- *\
|                               ApiCompetitor
\* -------------------------------------------------------------------------------- */
export class ApiCompetitor
{
    public displayname: string = "";
    public number: number = 0;
}
/* -------------------------------------------------------------------------------- *\
|                               ApiTrack
\* -------------------------------------------------------------------------------- */
export class ApiTrack
{
    public label: string = "";
    public datas: string = "";
}
/* -------------------------------------------------------------------------------- *\
|                               ApiRace
\* -------------------------------------------------------------------------------- */
export class ApiRace
{
    public label: string = "";
    public distance: number = 0;
    public competitors: ApiCompetitor[] = [];
    public track: ApiTrack = new ApiTrack();
    public infos: string = "Loading...";
}
/* -------------------------------------------------------------------------------- *\
|                               ApiRunnerPosition
\* -------------------------------------------------------------------------------- */
export class ApiRunnerPosition
{
    public number: number = 0;
    public lat: number = 0;
    public lng: number = 0;
    public speed: number = 0;
}
/* -------------------------------------------------------------------------------- *\
|                               RunnerPosition
\* -------------------------------------------------------------------------------- */
export class RunnerPosition
{
    public number: number = 0;
    public lat: number = 0;
    public lng: number = 0;
    public name: string = "";
    public speed: number = 0;
}
/* -------------------------------------------------------------------------------- *\
|                               CompetitorMarker
\* -------------------------------------------------------------------------------- */
export class CompetitorMarker
{
    public number: number = 0;
    public name: string = "";
    public marker: google.maps.marker.AdvancedMarkerElement | null = null;
}
/* -------------------------------------------------------------------------------- *\
|                               findcolor
\* -------------------------------------------------------------------------------- */
export function findcolor(num: number)
{
    let color = { color: '', border: '', font: '' };
    const number = isNaN(Number(num)) ? -1 : (Number(num) % 21) + 1;
    switch (number)
    {
        case 1:
            color.color = 'ff557b';
            color.border = 'ff557b';
            color.font = 'ffffff';
            break;
        case 2:
            color.color = 'dddddd';
            color.border = '373633';
            color.font = '373633';
            break;
        case 3:
            color.color = '0084c9';
            color.border = 'ffffff';
            color.font = 'ffffff';
            break;
        case 4:
            color.color = 'f7fc0f';
            color.border = '373633';
            color.font = '373633';
            break;
        case 5:
            color.color = '4b7f56';
            color.border = 'ffffff';
            color.font = 'ffffff';
            break;
        case 6:
            color.color = '3f3e36';
            color.border = 'ffffff';
            color.font = 'f3fc57';
            break;
        case 7:
            color.color = 'ff6e6e';
            color.border = 'ffffff';
            color.font = '443029';
            break;
        case 8:
            color.color = 'fc6d87';
            color.border = 'ffffff';
            color.font = '443029';
            break;
        case 9:
            color.color = '008eba';
            color.border = 'ffffff';
            color.font = '443029';
            break;
        case 10:
            color.color = '8669a5';
            color.border = 'ffffff';
            color.font = 'ffffff';
            break;
        case 11:
            color.color = 'd7d4c7';
            color.border = 'ffffff';
            color.font = 'e77787';
            break;
        case 12:
            color.color = '4ebf5f';
            color.border = 'ffffff';
            color.font = '2b3d20';
            break;
        case 13:
            color.color = '624742';
            color.border = 'ffffff';
            color.font = 'ffffff';
            break;
        case 14:
            color.color = '7c4c57';
            color.border = 'ffffff';
            color.font = 'fcf84d';
            break;
        case 15:
            color.color = 'ada68c';
            color.border = 'ffffff';
            color.font = '3e362d';
            break;
        case 16:
            color.color = '8febee';
            color.border = 'ffffff';
            color.font = 'fc6082';
            break;
        case 17:
            color.color = '4a585f';
            color.border = 'ffffff';
            color.font = 'ffffff';
            break;
        case 18:
            color.color = '454d47';
            color.border = 'ffffff';
            color.font = 'f3fb48';
            break;
        case 19:
            color.color = '56637d';
            color.border = 'ffffff';
            color.font = 'e8638a';
            break;
        case 20:
            color.color = 'fe607d';
            color.border = 'ffffff';
            color.font = 'fedc66';
            break;
        case 21:
            color.color = 'a7a1c3';
            color.border = 'ffffff';
            color.font = '494d5b';
            break;
        default:
            color.color = 'a7a1c3';
            color.border = 'ffffff';
            color.font = '494d5b';
            break;
    }
    return color;
}
/* -------------------------------------------------------------------------------- *\
|                               getapiurl
\* -------------------------------------------------------------------------------- */
export function getapiurl(): string
{
    var url = 'https://eventmaps-api.mclloyd.com';
    if (process.env.REACT_APP__API_URL)
    {
        url = process.env.REACT_APP__API_URL;
    }
    return url;
}
/* -------------------------------------------------------------------------------- *\
|                               getwsurl
\* -------------------------------------------------------------------------------- */
export function getwsurl(): string
{
    var url = 'https://eventmaps-ws.mclloyd.com';
    if (process.env.REACT_APP__WS_URL)
    {
        url = process.env.REACT_APP__WS_URL;
    }
    return url;
}