// import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { APIProvider, Map, useMap } from '@vis.gl/react-google-maps';
import { FC, ReactNode, useEffect, useState } from 'react';
import { usePositionsContext, useRaceContext } from '../app';
import { findcolor, CompetitorMarker } from '../types';
import './map.scss';
import { Loader } from './loader';

const INDEX_LATITUDE_CORDE_INTERIEURE = 1;
const INDEX_LONGITUDE_CORDE_INTERIEURE = 2;
// const INDEX_LATITUDE_CORDE_A_X_METRE = 3;
// const INDEX_LONGITUDE_CORDE_A_X_METRE = 4;
const INDEX_LATITUDE_CORDE_EXTERIEURE = 5;
const INDEX_LONGITUDE_CORDE_EXTERIEURE = 6;
// const INDEX_NOM_PORTE = 7;

/* -------------------------------------------------------------------------------- *\
|                               isValid
\* -------------------------------------------------------------------------------- */
function isValid(line: string[]): boolean
{
    const isFirstLine = line[0] === "DIST";
    return !isFirstLine;
}
/* -------------------------------------------------------------------------------- *\
|                               convertSpeed
\* -------------------------------------------------------------------------------- */
function convertSpeed(speed_ms: number, unit: string): number
{
    var ret: number = speed_ms;
    if (unit === "mph")
    {
        ret = ret * 2.23694;
    }
    else if (unit === "kph")
    {
        ret = ret * 3.6;
    }
    else if (unit === "kmh")
    {
        ret = ret * 3.6;
    }
    return Math.floor(10 * ret) / 10;
}
/* -------------------------------------------------------------------------------- *\
|                               MapView
\* -------------------------------------------------------------------------------- */
export const MapView : FC<{children?: ReactNode}> = () =>
{
    const [track, setTrack] = useState<string>();
    const [loading, setLoading] = useState(true);
    const [unit] = useState("kph");
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [mapZoom] = useState(12);
    const [mapCenter] = useState({lat: 43.628908, lng: 3.909600 });
    const [geotrack, setGeoTrack] = useState<google.maps.LatLng[]>();
    const [runners, setRunners] = useState<CompetitorMarker[]>([]);
    const positions = usePositionsContext();
    const race = useRaceContext();

    const MapComponent = () =>
    {
        const currentmap = useMap();
        useEffect(() =>
        {
          if (!currentmap) return;
          setMap(currentmap)
          currentmap.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
        }, [currentmap]);
      
        return <></>;
    };
    
    useEffect(() =>
    {
        if (map && track !== race?.track?.datas)
        {
            // console.log("New track")
            setTrack(race?.track?.datas ? race?.track?.datas : "");

            var csv:string = race?.track?.datas ? race?.track?.datas : "";

            const lines: string[][] = csv.replace(/\r/g, '').split(/\n/).map(line => line.split(';')).filter(values => values.length >= 6).filter(values => isValid(values));

            var gtrack: google.maps.LatLng[] = [];
            for(const line of lines)
            {
                var pos: google.maps.LatLng = new google.maps.LatLng
                (
                    (Number(line[INDEX_LATITUDE_CORDE_INTERIEURE]) + Number(line[INDEX_LATITUDE_CORDE_EXTERIEURE])) / 2,
                    (Number(line[INDEX_LONGITUDE_CORDE_INTERIEURE]) + Number(line[INDEX_LONGITUDE_CORDE_EXTERIEURE])) / 2
                );
                gtrack.push(pos);
            }

            if (gtrack.length > 0)
            {
                var pt1: google.maps.LatLng = gtrack[0];
                var simplified: google.maps.LatLng[] = [];

                if (pt1) simplified.push(pt1);

                for (var i = 1; i + 1 < gtrack.length; i++)
                {
                    var pt2: google.maps.LatLng = gtrack[i];
                    var pt3: google.maps.LatLng = gtrack[i + 1];

                    var v1 = { x: pt2.lat() - pt1.lat(), y: pt2.lng() - pt1.lng() };
                    var v2 = { x: pt3.lat() - pt2.lat(), y: pt3.lng() - pt2.lng() };

                    var det = Math.abs(v1.x * v2.y - v1.y * v2.x);
                    if (Math.abs(det) < 0.00000001)
                    {
                        
                    }
                    else
                    {
                        pt1 = pt2;
                        simplified.push(pt1);
                    }
                }
                if (gtrack.length > 2) simplified.push(gtrack[gtrack.length - 1]);
                setGeoTrack(simplified);
            }
            setLoading(false);
        }
    // eslint-disable-next-line
    }, [race, map, track]);

    useEffect(() =>
    {
        try
        {
            if (map && positions)
            {
                const parser = new DOMParser();
                var icontxt: string = '';
                icontxt += '<div class="mc-cont" style="display: #DISPLAY#;">';
                // icontxt += '  <div class="mc-circle"></div>';
                icontxt += '  <div class="mc-bigrect">';
                icontxt += '    <div class="mc-athlete-card" style="border-color:#BACK#;">';
                icontxt += '      <div class="mc-athlete-info" >';
                icontxt += '        <div class="mc-athlete-name">#NAME#</div>';
                icontxt += '        <div class="mc-athlete-metric" style="background-color:#BACK#;">';
                icontxt += '          <div class="mc-metric-text" style="color: #TEXT#;">#SPEED#</div>';
                icontxt += '        </div>';
                icontxt += '      </div>';
                icontxt += '    </div>';
                icontxt += '  </div>';
                icontxt += '  <div class="mc-triangle" style="border-top-color:#BACK#;">';
                icontxt += '  </div>';
                icontxt += '</div>';

                // console.log("New positions " + positions.length);
                var markers: CompetitorMarker[] = [];

                for(const p of positions)
                {
                    const show: boolean = p.lat !== 0 && p.lng !== 0;
                    var find = runners.find(n => n.number === p.number);
                    // if (show)
                    // {
                        if (find)
                        {
                            // console.log("update");
                            var m: CompetitorMarker = find;
                            if (show && m.marker) m.marker.position = { lat: p.lat, lng: p.lng };
                            markers.push({number: p.number, name: p.name, marker: find.marker});

                            const color = findcolor(p.number);
                            const txtspeed: string = p.speed > 0 ? convertSpeed(p.speed, unit) + unit : "--.-";
                            const div = icontxt.replace('#NN#', '' + p.number).replace('#NAME#', '' + p.name).replace('#SPEED#', txtspeed).replace(/#BACK#/g, '#' + color.color).replace('#TEXT#', '#' + color.font);
                            const icon = parser.parseFromString(div, 'text/html').documentElement;
                            if (find.marker) find.marker.content = icon;
                        }
                        else if (show)
                        {
                            // console.log("add " +  p.name);
                            const color = findcolor(p.number);
                            const txtspeed: string = p.speed > 0 ? convertSpeed(p.speed, unit) + unit : "--.-";
                            const div = icontxt.replace('#NN#', '' + p.number).replace('#NAME#', '' + p.name).replace('#SPEED#', txtspeed).replace(/#BACK#/g, '#' + color.color).replace('#TEXT#', '#' + color.font);
                            const icon = parser.parseFromString(div, 'text/html').documentElement;
                        
                            var gm: google.maps.marker.AdvancedMarkerElement = new google.maps.marker.AdvancedMarkerElement(
                            {
                                map: map,
                                content: icon,
                                position: { lat: p.lat, lng: p.lng }
                            });
                            markers.push({number: p.number, name: p.name, marker: gm});
                        }
                    }
                // }

                // const newnumbers = markers.map(n => n.number);
                // const todel = runners.filter(n => newnumbers.indexOf(n.number) === -1);
                // for(const m of todel)
                // {
                //     console.log("remove");
                //     if (m.marker) m.marker.map = null;
                // }

                setRunners(markers);
            }
        }
        catch (error)
        {
            console.log(error);
        }
    // eslint-disable-next-line
    }, [positions]);

    useEffect(() =>
    {
        // console.log("New drawing " + map)
        if (geotrack && map && geotrack.length > 0)
        {
            try
            {
                let bounds = new window.google.maps.LatLngBounds();
                for (let i = 0; i < geotrack.length; i++)
                {
                    bounds.extend(geotrack[i]);
                }
                if (map && !bounds.isEmpty())
                {
                    map.setCenter(bounds.getCenter());
                    map.fitBounds(bounds);
                }

                var p = new google.maps.Polyline
                (
                    {
                        strokeColor: "#000044",
                        strokeOpacity: 0.8,
                        strokeWeight: 4,
                    }
                );
                p.setPath(geotrack);
                p.setMap(map);
            }
            catch(error)
            {
                console.log(error);
            }
        }
    // eslint-disable-next-line
    }, [geotrack, map]);
    
    const mclloyd = process.env.REACT_APP__MCLLOYD ? process.env.REACT_APP__MCLLOYD : 'https://mclloyd.com/';

    return (
        <div className='mapcont'>
            <div className='mapcont__map'>
                <APIProvider apiKey={"AIzaSyBrpmH0oBXL6NeBeYQsS-y90ySz8kXhv54"} libraries={["marker"]}>
                    { loading ? <Loader/> : <></> }
                    <Map
                        mapId={"bb5613e425227843"}
                        defaultZoom={mapZoom}
                        defaultCenter={mapCenter}
                        clickableIcons={false}
                        streetViewControl={false}
                        rotateControl={false}
                        maxZoom={20}
                        mapTypeControl={true}
                        >
                        </Map>
                    <MapComponent/>
                </APIProvider>
            </div>
            <div className='mapcont__logo'>
                <a href={mclloyd} rel="noreferrer" target='_blank'>
                    <img className="logo1" src={'/images/logo1.png'} alt="McLloyd"/>
                    <img className="logo2" src={'/images/logo2.png'} alt="McLloyd"/>
                </a>
            </div>
        </div>
    );
}
