import axios from "axios";
import { ApiRace, ApiRunnerPosition, getapiurl } from './types';

/* -------------------------------------------------------------------------------- *\
|                               api_get_race
\* -------------------------------------------------------------------------------- */
export const api_get_race = (): Promise<ApiRace> =>
{
    var url = 'https://eventmaps-api.mclloyd.com';
    if (process.env.REACT_APP__API_URL)
    {
        url = process.env.REACT_APP__API_URL
    }
    return Promise.resolve()
        .then(() => axios.get(url + '/currentrace'))
        .then(response => response.data)
        ;
}
/* -------------------------------------------------------------------------------- *\
|                               api_get_positions
\* -------------------------------------------------------------------------------- */
export const api_get_positions = (): Promise<ApiRunnerPosition[]> =>
    {
        var url = getapiurl();
        return Promise.resolve()
            .then(() => axios.get(url + '/currentpositions'))
            .then(response => response.data)
            ;
    }
    