import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { api_get_race } from './api.service';
import './app.scss';
import { MapView } from './components/map';
import { ApiRace, ApiRunnerPosition, RunnerPosition } from './types';
import { WebClient } from './webclient';
import { InfosView } from './components/infos';

var g_nextgetrace: Date = new Date();
var g_ingetrace: boolean = false;

export const RaceContext = createContext<ApiRace | null>(null);
export const useRaceContext = () =>
{
    return useContext(RaceContext);
};
const PositionsContext = createContext<RunnerPosition[]>([]);
export const usePositionsContext = () =>
{
    return useContext(PositionsContext);
};

/* -------------------------------------------------------------------------------- *\
|                               App
\* -------------------------------------------------------------------------------- */
function App()
{
    const [currentrace, setRace] = useState<ApiRace>(new ApiRace());
    const [positions, setPositions] = useState<RunnerPosition[]>([]);
    const [apipositions, setApiPositions] = useState<ApiRunnerPosition[]>([]);

    const ws_onmessage = (cmd: string, datas: any) => 
    {
        const loc = window.location;
        if (cmd === "positions")
        {
            setApiPositions(Object.assign([], datas));
        }
        if (cmd === "forcereload")
        {
            window.location = loc;
        }
    }
    const ws = useRef<WebClient>(new WebClient(ws_onmessage));

    const closeSocket = () => 
    {
        ws.current.close();
    }
    useEffect(() =>
    {
        if (ws.current)
        {
            ws.current.connect();
        }
        window.addEventListener('beforeunload', closeSocket);
        return () =>
        {
            window.removeEventListener('beforeunload', closeSocket);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() =>
    {
        const interval = setInterval(() =>
        {
            if (!g_ingetrace && g_nextgetrace < new Date())
            {
                g_ingetrace = true;
                api_get_race().then((race: ApiRace) =>
                {
                    setRace(race);
                })
                .catch(() => setRace(new ApiRace()))
                .finally(() =>
                {
                    var tempo: number = Number(process.env.REACT_APP__GETRACE_TEMPO);
                    if (!tempo || tempo < 1) tempo = 120;
                    g_nextgetrace = new Date(new Date().getTime() + tempo * 1000);

                    g_ingetrace = false;
                });
            }            
        }, 300);

        return () => clearInterval(interval);
    }, []);

    useEffect(() =>
    {
        var infos: RunnerPosition[] = [];
        if (currentrace && currentrace.competitors.length > 0)
        {
            for(const ap of apipositions)
            {
                var name = currentrace?.competitors.find(c => c.number === ap.number)?.displayname;
                infos.push({number: ap.number, lat: ap.lat, lng: ap.lng, name: name ? name : "", speed: ap.speed});
            }
        }
        setPositions(infos);

    }, [apipositions, currentrace]);

    return (
        <div className="app">
            <RaceContext.Provider value={currentrace}>
                <PositionsContext.Provider value={positions}>
                {
                    !currentrace.infos && <MapView/>
                }
                {
                    currentrace.infos && <InfosView msg={currentrace.infos}/>
                }
                </PositionsContext.Provider>
            </RaceContext.Provider>
        </div>
    );
}

export default App;
