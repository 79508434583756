import { FC, ReactNode } from 'react';
import './infos.scss';

/* -------------------------------------------------------------------------------- *\
|                               InfosView
\* -------------------------------------------------------------------------------- */
export const InfosView: FC<{ children?: ReactNode, msg: string }> = ({ msg = '' }) =>
{
    const ishtml: boolean = msg.startsWith("<");
    const mclloyd = process.env.REACT_APP__MCLLOYD ? process.env.REACT_APP__MCLLOYD : 'https://mclloyd.com/';

    return (
        <div className='infos'>
        {
            !ishtml && <div className='infos__text'>{msg}</div>
        }
        {
            ishtml && <div className='infos__html' dangerouslySetInnerHTML={{__html: msg}}></div>
        }
        <div className='infos__logo'>
            <a href={mclloyd} rel="noreferrer" target='_blank'>
                <img className="logo1" src={'/images/logo1.png'} alt="McLloyd"/>
                <img className="logo2" src={'/images/logo2.png'} alt="McLloyd"/>
            </a>
        </div>
        </div>
    );
}