import { FC, ReactNode } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './loader.scss';

const antIcon = <LoadingOutlined style={{ color:"white", fontSize: 54 }} spin />;

/* -------------------------------------------------------------------------------- *\
|                               Loader
\* -------------------------------------------------------------------------------- */
export const Loader : FC<{children?: ReactNode}> = () =>
{
  return (
    <div className='loadercont'>
      <Spin className='loader' indicator={antIcon} />
    </div>
  );
}